
import Header from '~/components/Common/Layout/Header'
import Footer from '~/components/Common/Layout/Footer'
import TabMenu from '~/components/Common/UI/TabMenu'

export default {
  components: { Header, TabMenu },
  data() {
    return {
      d: 'd',
    }
  },
  computed: {
    tabData() {
      return this.$store.state.tabs.tabData
    },
  },
  created() {
    console.log('dfdfdfddfdf', this.da())
  },
  mounted() {
    const d = document.querySelector('tabs')
  },
  methods: {
    da(value) {
      console.log(value)
    },
  },
}
