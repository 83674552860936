
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
// import AutoPay from './AutoPay.vue'
import api from '~/api/' // server
import storage, { keys } from '~/services/storage'

import {
    PAYMENT_TYPES,
    PAYMENT_PERIODS,
    CASH_RECEIPT_TYPES,
} from '~/constants/payment'

export default {
    name: 'Payment',
    // components: {
    //     AutoPay
    // },
    data() {
        return {
            isWindowOpenRequestPayment: false,
            paymentPeriodIdx: -1, // 0: 1주일, 1: 1개월, 2: 3개월, 3: 6개월, 4: 12개월
            paymentTypeIdx: -1,
            depositName: '',
            cashReceiptNumber: '',
            cashReceiptType: '',
            reqParams: null, // req_params 값, 결제 새창에 보낼 값
            reqUrl: '',

            PAYMENT_TYPES,
            PAYMENT_PERIODS,
            CASH_RECEIPT_TYPES,
        }
    },
    computed: {
        ...mapState('member', ['showPayment', 'memberInfo']),
        ...mapState('modal', [
            'paymentShow',
            'curChangeIdx',
            'showPaymentLocal',
            // 'paymentTypeIdx',
        ]),
        ...mapMutations('modal', [
            'SET_SHOWPAYMENTLOCAL',
            'SET_PAYMENT_SHOW',
            // 'SET_PAYMENTTYPEIDX',
        ]),

        // 외부 결제창 띄우는지 여부
        isWindowOpenPayment() {
            if (parseInt(this.paymentTypeIdx) === 3) {
                return false
            } else {
                return true
            }
        },
    },
    mounted() {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://tpay.billgate.net/paygate/plugin/gx_web_client.js'
        document.body.appendChild(script)
    },
    methods: {
        ...mapActions('modal', [
            'SHOW_ALERT',
            'HIDE_MODAL',
            'SHOW_DEPOSIT',
            'PUT_DEPOSIT_PERIOD',
            'SHOW_PAYMENT',
            'HIDE_PAY_MODAL',
        ]),
        checkSubmitAuto(viewType) {
            // const HForm = document.payment;

            // const serviceId = HForm.SERVICE_ID.value;
            const serviceId = 'glx_at'
            // const serviceCode = document.getElementById("selectPay").value;
            const serviceCode = '0900'

            if (serviceCode === 'null' || serviceCode === '') {
                alert('결제수단을 선택해주세요.')
                return
            }

            if (viewType === 'null' || viewType === '') {
                alert('뷰 타입을 입력해주세요.')
            }
            /*
            GX_pay(
            frmName : 결제 form name 입력, 
            viewType : layerpopup 레이어팝업, popup : 윈도우팝업, submit : 페이지 이동 
            protocolType : http_tpay(테스트 http), https_tpay(테스트 https), https_pay(상용 https)
            */
            console.log("test autopay = ", window.app.$root)
            this.GX_pay('payment', viewType, 'https_tpay')
        },
        setPaymentShow() {
            // 220608 meej 결제 모달 숨김 분리로 인한 변경
            // this.HIDE_MODAL()
            this.HIDE_PAY_MODAL()

            this.paymentTypeIdx = -1
            this.isWindowOpenRequestPayment = false
            this.paymentPeriodIdx = -1
            console.log('paymentShow: ', this.paymentShow)
        },
        onClickPayment() {
            // 결제 방법이 선택 안되어 있으면 중지
            if (this.paymentTypeIdx === -1) {
                this.SHOW_ALERT({ content: '결제 방법을 선택해 주세요.' })
                return
            }

            // 가입기간이 선택 안되어 있으면 중지
            if (this.paymentPeriodIdx === -1) {
                // console.log("this.paymentTypeIdx  = ",this.paymentTypeIdx)
                // if (this.paymentPeriodIdx === -1 && this.paymentTypeIdx!==3) {
                this.SHOW_ALERT({ content: '가입 기간을 선택해 주세요.' })
                return
            }

            // 무통장 입금
            if (this.paymentTypeIdx === 2) {
                this.SHOW_DEPOSIT({ callback: this.payDeposit }) // 무통장 입금 modal 보여주기
                return
            }

            if (!this.isWindowOpenRequestPayment) {
                // 무통장 입금 외 결제(외부 결제창을 띄우는 경우)
                this.requestPayment()
            } else {
                this.confirmPayment()
            }
        },

        // callback by 무통장 입금
        payDeposit(depositInfo) {
            console.log('payDeposit depositInfo', depositInfo)

            console.log('CALL_BACK=', depositInfo)

            this.depositName = depositInfo.depositName
            this.cashReceiptNumber = depositInfo.cashReceiptNumber
            this.cashReceiptType =
                CASH_RECEIPT_TYPES[depositInfo.cashReceiptTypeIdx].code

            this.requestPayment()
        },

        async requestPayment() {
            let params = {}
            let res = {}

            if (PAYMENT_TYPES[this.paymentTypeIdx].code === 'DEPOSIT') {
                console.log('3417839473140983124708412390')
                //   무통장은 결제가 아니라 DB들록 이기 떄문에 수정.
                params = {
                    type: 'DEPOSIT',
                    // 요청
                    // period: PAYMENT_PERIODS[this.paymentPeriodIdx].code, // 1w,1m,3m,6m,1y	가입기간(1주일, 한달, 3달, 6달, 1년
                    // amount: PAYMENT_PERIODS[this.paymentPeriodIdx].price.toString(), // 금액
                    // 요청
                    name: this.depositName, // Option : 입금자명 - 무통장입금에서만 사용
                    amount: PAYMENT_PERIODS[
                        this.paymentPeriodIdx
                    ].price.toString(), // 금액
                    period: PAYMENT_PERIODS[this.paymentPeriodIdx].code, // 1w,1m,3m,6m,1y	가입기간(1주일, 한달, 3달, 6달, 1년
                    issueNumber: this.cashReceiptNumber, // Option : 현금영수증 정보(소득공제, 지출증비용)
                    issueType: this.cashReceiptType, //	N(미발행) / I(소득공제용) / E(지출증빙용)	Option : 현금영수증 타입 :
                }
                res = await api.requestPayment(params)

                console.log('무통장RES=', res)

                if (res === undefined) {
                    this.HIDE_MODAL()

                    // 220608 meej 결제 모달 숨김 분리로 인한 추가
                    this.HIDE_PAY_MODAL()

                    this.SHOW_ALERT({ content: '서버 확인필요' })
                    return
                }
                this.PUT_DEPOSIT_PERIOD(res)
            } else {
                //   무통장 외 결제
                params = {
                    // 필수값
                    id: this.memberInfo.id,
                    type: PAYMENT_TYPES[this.paymentTypeIdx].code, // CARD, MOBILE, ACCOUNT, DEPOSIT(변경가능)	카드, 휴대폰, 실시간계좌이체, 무통장입금
                    period: PAYMENT_PERIODS[this.paymentPeriodIdx].code, // 1w,1m,3m,6m,1y	가입기간(1주일, 한달, 3달, 6달, 1년
                    amount: PAYMENT_PERIODS[
                        this.paymentPeriodIdx
                    ].price.toString(), // 금액

                    // 선택값
                    name: this.depositName, // Option : 입금자명 - 무통장입금에서만 사용
                    num: this.cashReceiptNumber, // Option : 현금영수증 정보(소득공제, 지출증비용)
                    receipt: this.cashReceiptType, //	N(미발행) / I(소득공제용) / E(지출증빙용)	Option : 현금영수증 타입 :
                }
                res = await api.requestPayment(params)
            }
            console.log('requestPayment res', res)

            if (res.success) {
                // 무통장 입금이 아닐 때만 외부 결제창 열기
                this.reqParams = res.data.req_params
                this.reqUrl = res.data.req_url

                this.$nextTick(() => {
                    this.checkSubmit()
                })

                // 결제하기 버튼 상태 변경을 위해
                this.isWindowOpenRequestPayment = true
            }
        },

        async confirmPayment() {
            const params = {
                id: this.memberInfo.id,
            }

            const res = await api.confirmPayment(params)
            console.log('confirmPayment res', res)

            if (res.data.status === '405') {
                // 실패 시
                const message = res.data.message
                this.SHOW_ALERT({ content: message })

                return
            }

            if (res.data.status === '0') {
                // 0: 성공
                // 성공 시
                // const message = res.status.message
                const message = '결제가 성공 하였습니다.'
                this.SHOW_ALERT({ content: message })
                this.$parent.getMemberInfo()
                this.SET_SHOW_PAYMENT(false)
                this.SET_SHOWPAYMENTLOCAL(false)
            }
        },

        checkSubmit() {
            const HForm = document.payment
            console.log('document.payment? ', document.payment)
            HForm.target = 'payment'

            const option =
                'width=500,height=477,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,left=150,top=150'
            const objPopup = window.open('', 'payment', option)

            // 팝업 차단여부 확인
            if (objPopup == null) {
                alert(
                    '팝업이 차단되어 있습니다.\n팝업차단을 해제하신 뒤 다시 시도하여 주십시오.'
                )
            }

            // this.SET_DEPOSIT_PAGE()

            HForm.submit()
        },
    },
}
