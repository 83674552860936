
import { mapState } from 'vuex'
export default {
    name: 'Introduction',
    data() {
        return {
            title: false,
            business: false,
            platform: false,
        }
    },
    computed: {
        ...mapState('modal', ['introduction']),
    },
}
