
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  name: 'Alert',

  computed: {
    ...mapState('modal', ['alertInfo']),
  },

  methods: {
    ...mapActions('modal', ['HIDE_MODAL']),

    onClickClose() {
      this.close()
    },
    onClickConfirm() {
      this.close()
    },

    close() {
      this.alertInfo.callback && this.alertInfo.callback()
      this.HIDE_MODAL()
    },
  },
}
