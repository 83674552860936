
import Menu from '~/constants/Menu'

const MENU_GROUP_STYLES = [
    {},
    {},
    { width: '30%', left: '19%' }, // 테마
    {},
    { width: '40%', left: '32%' }, // 리서치
    { width: '40%', left: '19.5%' }, // IR컨설팅/B2B서비스
    { width: '30%', left: '42%' }, // 뉴스/미디어
]

export default {
    components: {},

    data() {
        return {
            Menu,
            MENU_GROUP_STYLES,
            isHover: false,
        }
    },

    methods: {
        hiddenMenu() {
            this.isHover = false
        },
        test(aaa){
            this.NAV_GROUP_STYLES[aaa] = {color:'blue'}
        },
        onClickOpenExternalLink(uri) {
            window.open(uri, '_blank')
        },
    },
}
