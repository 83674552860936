
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

import SlideUpDown from 'vue-slide-up-down'

import Nav from './Nav'
import NewsTicker from './NewsTicker'
import Notice from './Notice'
import HeaderSearch from './HeaderSearch'
import MobileFooter from '~/components/Common/Layout/Footer/MobileFooter.vue'

import Menu from '~/constants/Menu'

export default {
    components: { Nav, NewsTicker, HeaderSearch, SlideUpDown, MobileFooter, Notice },

    data() {
        return {
            curMenuIdx: -1,

            slideDuration: 300,

            Menu,

            windowHeight: 0,
        }
    },

    computed: {
        ...mapState('ui', ['mobileMenuShow', 'isVisible']),
        ...mapState('member', ['memberInfo']),
    },

    watch: {
        $route(to, from) {
            // console.log('$route(to, from)', to, from)
            this.SET_MOBILE_MENU_SHOW(false)
        },
        windowHeight(curr, prev) {
            const ref = this.$refs.mobileHeader

            if (curr < 50) {
                ref.style.top = '0px'
                return
            }

            if (prev > curr) {
                ref.style.top = '0px'
            } else {
                ref.style.top = '-50px'
            }
        },
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },

    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        ...mapMutations('ui', ['SET_MOBILE_MENU_SHOW']),
        ...mapActions('modal', ['SHOW_ALERT', 'SHOW_FIND_ID', 'SHOW_LOGIN']),
        ...mapActions('main', ['GET_RELOAD_MAIN']),

        // onClickLoginModal() {
        //     this.SHOW_LOGIN()
        // },

        onClickMobileMenu(idx) {
            this.curMenuIdx = idx === this.curMenuIdx ? -1 : idx
        },

        onClickMobileMenuOpen() {
            this.SET_MOBILE_MENU_SHOW(true)
        },
        onClickMobileMenuClose() {
            this.SET_MOBILE_MENU_SHOW(false)
        },

        onClickLogout() {
            this.$method.logout()
            this.SET_MOBILE_MENU_SHOW(false)
            // redirect('/')
        },

        onClickLogo() {
            this.GET_RELOAD_MAIN()
            console.log('RELOAD')

            // 22.05.30 suye 메인화면 리로드 
            if(this.$route.path === '/'){
                this.$router.go()
            } else {
                this.$router.push('/')
            }
            
        },

        handleScroll() {
            const value = window.pageYOffset
            this.windowHeight = value
        },
    },
}
