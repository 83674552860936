
import anime from 'animejs/lib/anime.js'

const TARGET_MARGIN_TOP = -18
let isAniming = false // 연속된 anime 막기 위해 사용
let intervalId = null

export default {
  name: 'TickerVertical',

  data() {
    return {
      // index: 0,
      news: [
        {
          date: '2020-10-28',
          time: '14:56:41',
          summary:
            "[특징주] KMH, 전환사채발행금지 가처분 소송 기각에 '강세' [특징주] KMH, 전환사채발행금지 가처분 소송 기각에 '강세' ",
        },
        {
          date: '2020-10-28',
          time: '14:56:41',
          summary: 'LGU+, 로보월드서 5G 무인지게차,물류로봇 첫선',
        },
        {
          date: '2020-10-28',
          time: '14:55:45',
          summary: '다산네트웍스(039560) 소폭 상승세 +3.41%',
        },
        {
          date: '2020-10-28',
          time: '14:55:23',
          summary: '아비코전자(036010) 소폭 상승세 +4.11%',
        },
        {
          date: '2020-10-28',
          time: '14:54:35',
          summary:
            'STX중공업(071970) 소폭 상승세 +4.79%, 외국계 매수 유입, 5거래일만에 반등',
        },
      ],
    }
  },

  created() {
    intervalId = setInterval(this.updateTicker, 3000)
  },
  methods: {
    goNext() {
      this.stopRolling() // 자동 스크롤 중지

      if (isAniming) return
      isAniming = true

      // console.log('this.$refs', this.$refs)
      const ul = this.$refs.ul
      if (!ul) return

      const li = ul.getElementsByTagName('li')[0]
      // console.log('li', li)

      const _this = this

      anime({
        targets: li,
        marginTop: TARGET_MARGIN_TOP,
        // backgroundColor: '#FFF',
        // borderRadius: ['0%', '50%'],
        easing: 'easeInOutQuad',
        duration: 500,
        complete(anim) {
          _this.startRolling()

          isAniming = false

          ul.appendChild(li)
          anime({
            targets: li,
            marginTop: 0,
            // backgroundColor: '#FFF',
            // borderRadius: ['0%', '50%'],
            duration: 0,
          })
        },
      })
      /* this.index++
      if (this.index === this.news.length) {
        this.index = 0
      } */
      // const removed = this.news.pop()
      // this.news.unshift(removed)
      // this.news.push({
      //   time: '12:47',
      //   summary: 'Look its Superman!',
      // })
    },
    goPrev() {
      this.stopRolling() // 자동 스크롤 중지

      if (isAniming) return
      isAniming = true

      console.log('this.$refs', this.$refs)
      const ul = this.$refs.ul
      const LIs = ul.getElementsByTagName('li')
      const firstLI = LIs[0]
      const lastLI = LIs[LIs.length - 1]
      ul.insertBefore(lastLI, firstLI)

      const _this = this

      anime({
        targets: lastLI,
        marginTop: TARGET_MARGIN_TOP,
        // easing: 'easeInOutQuad',
        duration: 0,
      })
      anime({
        targets: lastLI,
        marginTop: 0,
        easing: 'easeInOutQuad',
        duration: 500,
        complete(anim) {
          _this.startRolling()
          isAniming = false
        },
      })
    },
    updateTicker() {
      this.goNext()
    },

    startRolling() {
      intervalId = setInterval(this.updateTicker, 3000)
    },
    stopRolling() {
      clearInterval(intervalId)
    },
  },
}
