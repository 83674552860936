
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

export default {
    name: 'HeaderSearch',
    data() {
        return {
            inputText: '',
            currentFocus: -1,
            toggle: false,
        }
    },

    computed: {
        // 입력과 일치하는 종목코드 필터링해주는 부분
        ...mapState('initial', ['initial']),
        CorpList() {
            const CorpList = this.initList() 
            console.log('HeaderSearch => CorpList ', CorpList)
           
            const parsedobj = CorpList && JSON.parse(JSON.stringify(CorpList))
            
            // const parsedobj = CorpList && JSON.parse(JSON.stringify(CorpList))

            if (this.inputText.length > 1) {
                const result =
                    parsedobj &&
                    parsedobj.filter(
                        (item) =>
                            item.code
                                .toLowerCase()
                                .includes(
                                    this.inputText.toLowerCase().trim()
                                ) ||
                            item.name
                                .toLowerCase()
                                .includes(this.inputText.toLowerCase().trim())
                    )
                console.log('result ', result)
                
                if (result.length === 0) {
                    return [{ name: '해당되는 종목이 없습니다.' }]
                } else {
                    // 22.05.18 suye 종목 검색 추천값에서 종목명순(길이,한글>영어) 정렬
                    result.sort((a, b) => {

                        if(a.name.length > b.name.length) { return 1 } 
                        if(a.name.length < b.name.length) { return -1 }

                        if(a.name > b.name) { return -1 } 
                        if(a.name < b.name) { return 1 }

                        if(a.name.localeCompare(b.name) > b.name.localeCompare(a.name)) { return -1 }
                        if(a.name.localeCompare(b.name) < b.name.localeCompare(a.name)) { return 1 }

                    }) 
                    // console.log('result SORTED ', result)

                    return result
                }
            }

            return ''
        },
    },
    watch: {
        // 두글자 이상 입력해야 자동완성 랜더링 되도록 만들어주는 조건
        inputText() {
            if (this.inputText.length > 1) {
                this.toggle = true
            } else {
                this.toggle = false
            }

            this.currentFocus = -1

            if (this.$refs.li_refs !== undefined) {
                this.removeActive()
            }
        },
    },
    beforeMount() {
        window.addEventListener('click', this.closeAllLists)
    },
    beforeDestroy() {
        window.removeEventListener('click', this.closeAllLists)
    },

    created() {
        this.requestInit()
    },
    methods: {
        ...mapActions('initial', ['GET_INIT_DATA']),
        ...mapGetters('initial', ['initList']),
        ...mapActions('search', ['GET_CORP_INFO']),
        ...mapMutations('search', ['SET_CORP_CODE']),

        // onClick() {
        //     if (this.inputText === '') {
        //         alert('코드나 종목명을 입력해주세요.')
        //     } else if (this.CorpList[0].code === undefined) {
        //         alert('종목이 상장폐지/존재하지 않는 종목입니다.')
        //     } else {
        //         this.linkPage()
        //     }
        //     this.inputText = ''
        // },

        /* 22.05.17 suye 종목검색 */
        hitEnter(event) {

            // 검색값 입력 제어 
            if (this.inputText.trim() === '') {
                alert('코드나 종목명을 입력해주세요.')
            } else if (this.CorpList[0].code === undefined) {
                alert('상장폐지되었거나 존재하지 않는 종목입니다.')
            } else {
            // 검색값 존재시 
                // selectBox 선택시
                if (this.currentFocus > -1) {
                const ref = this.$refs.li_refs
                const selectedItem = ref.filter(
                    (item) => item.className === 'autocomplete-active'
                )
                if (
                    selectedItem[0].textContent.trim() !==
                    '해당되는 종목이 없습니다.'
                ) {
                    const regexRule = /[0-9]{2}\s*-?\s*[0-9]{2}\s*-?\s*[0-9]{2}/
                    const code = regexRule.exec(selectedItem[0].textContent)[0]
                    this.SET_CORP_CODE(code)
                    this.GET_CORP_INFO(code)
                    this.$router.push(`/Company/Analysis/StockInfo`)
                }
                // selectBox 미선택시
                } else if (this.currentFocus === -1) {
                    try {
                        // 검색값 올바를 경우 이동
                        this.linkPage()      
                        } finally {
                            // 해당 검색 오류시 selectBox의 첫번째값 검색
                            const ref = this.$refs.li_refs
                            ref[0].className = 'autocomplete-active'
                            const selectedItem = ref
                            if (
                                selectedItem[0].textContent.trim() !==
                                '해당되는 종목이 없습니다.'
                            ) {
                                const regexRule = /[0-9]{2}\s*-?\s*[0-9]{2}\s*-?\s*[0-9]{2}/
                                const code = regexRule.exec(selectedItem[0].textContent)[0]
                                this.SET_CORP_CODE(code)
                                this.GET_CORP_INFO(code)
                                this.$router.push(`/Company/Analysis/StockInfo`)
                            }
                    }   
                } 
                this.inputText = ''
                event.target.value = ''
            } 
            this.$refs.headersearch.value = '' // 검색필드 클리어
        },
        // 종목 이름과 코드 서버에서 불러오는 메소드
        requestInit() {
            const params = {
                body: '',
            }
            this.GET_INIT_DATA(params)
        },

        linkPage() {
            this.GET_CORP_INFO(this.inputText)
            this.inputText = ''
            this.$router.push(`/Company/Analysis/StockInfo`)
        },
        // 자동완성 아이템 클릭 시, queryString으로 이동시키는 메소드
        onBoxClick(code) {
            if (code) {
                this.inputText = ''
                this.toggle = false
                this.currentFocus = -1
                this.$refs.headersearch.value = ''

                this.GET_CORP_INFO(code)
                this.$router.push(`/Company/Analysis/StockInfo`)
            }
        },
        // 아래로 키보드 눌렀을 때, 스크롤 포커스도 따라가게 만드는 메소드
        outboundScrolling() {
            const ref = this.$refs.li_refs

            if (
                ref[this.currentFocus].offsetTop <
                ref[this.currentFocus].parentNode.scrollTop
            ) {
                // Hidden on top, move scroll to show item
                // Just to the top of item
                ref[this.currentFocus].parentNode.scrollTop =
                    ref[this.currentFocus].offsetTop
            } else if (
                ref[this.currentFocus].offsetTop >
                ref[this.currentFocus].parentNode.scrollTop +
                    ref[this.currentFocus].parentNode.clientHeight -
                    ref[this.currentFocus].clientHeight
            ) {
                // Hidden on bottom, move scroll to top of item + item height
                ref[this.currentFocus].parentNode.scrollTop =
                    ref[this.currentFocus].offsetTop -
                    (ref[this.currentFocus].parentNode.clientHeight -
                        ref[this.currentFocus].clientHeight)
            }
        },
        // ese 눌렀을 때, 자동완성 hide
        keyEscape() {
            this.toggle = false
            this.currentFocus = -1
        },

        // 자동완성 아래방향 키입력
        keyDown() {
            const ref = this.$refs.li_refs

            if (ref !== undefined && ref.length - 1 > this.currentFocus) {
                this.removeActive()
                this.currentFocus++
                ref[this.currentFocus].classList.add('autocomplete-active')

                this.outboundScrolling()
            }
        },

        // 자동완성 아래방향 키입력
        keyUp() {
            const ref = this.$refs.li_refs

            if (this.currentFocus > 0) {
                this.removeActive()
                this.currentFocus--
                ref[this.currentFocus].classList.add('autocomplete-active')

                this.outboundScrolling()
            }
        },

        // autocomplete-active 클래스를 제거해주는 메소드
        removeActive() {
            for (let i = 0; i < this.$refs.li_refs.length; i++) {
                this.$refs.li_refs[i].classList.remove('autocomplete-active')
            }
        },
        // 자동완성 부분 외의 부분을 클릭할 시, 자동완성 토글창 닫도록 만드는 조건
        closeAllLists(elem) {
            const ref = this.$refs.li_refs

            if (
                elem.target.className !== 'autobox' &&
                elem.target.className !== 'header-searchbar'
            ) {
                this.toggle = false
            }
        },
    },
}
