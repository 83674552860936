
import Banner from '~/components/Common/Layout/Footer/Banner'
import { APP_VERSION } from '~/constants'

export default {
    name: 'MobileUnderFooter',
    components: {
        Banner,
    },
    data() {
        return {
            APP_VERSION,
        }
    },
}
