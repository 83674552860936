
import { mapState, mapActions, mapMutations } from 'vuex'
import api from '~/api'

export default {
    name: 'ChagePwd',
    data() {
        return {
            password: '',
            passwordConfirm: '',
            isPasswordConfirm: null,
            successChagePwd: true,
            openSuccessModal: false, // 재설정 완료창 여부 구분값
        }
    },
    computed: {
        ...mapState('modal', ['visibleChangeTab']),
    },
    watch: {
        seconds() {
            this.minutes(this.seconds)
        },
        id(val, oldVal) {
            // console.log('val, oldVal', val, oldVal)
            // id 입력 변경 시 idDupCheck 초기화
            this.idDupCheck = null
        },
        password(val, oldVal) {
            // console.log(
            //     'this.password, this.passwordConfirm',
            //     this.password,
            //     this.passwordConfirm
            // )

            if (this.password === this.passwordConfirm) {
                this.isPasswordConfirm = true
            } else {
                this.isPasswordConfirm = false
            }
        },
        passwordConfirm(val, oldVal) {
            // console.log(
            //     'this.password, this.passwordConfirm',
            //     this.password,
            //     this.passwordConfirm
            // )

            if (this.password === this.passwordConfirm) {
                this.isPasswordConfirm = true
            } else {
                this.isPasswordConfirm = false
            }
        },
    },  
    methods: {
        ...mapMutations('modal', ['SET_VISIBLECHANGETAB',]),
        ...mapActions('modal', ['HIDE_MODAL', 'SHOW_ALERT', 'SHOW_VISIBLECHANGETAB']),
        onSubmitAssociate() {
            // alert('Form has been submitted!')
            if (!this.isPasswordConfirm) {
                alert('비밀번호 확인 필요')
                return
            }
            this.openSuccessModal = true
            this.signUpAssociate()
        },
        onClickClose() { 
            this.password = ''
            this.passwordConfirm = ''
            this.isPasswordConfirm = null
            this.successChagePwd = true 
            this.openSuccessModal = false 
            this.SET_VISIBLECHANGETAB(false) // 부모 컴포에서 닫아줘야 설정 후 초기화 정상 동작
            this.HIDE_MODAL()
        },
        async signUpAssociate() {
            const res = await api.changePwd({
                newPasswd: this.$CryptoJS.SHA1(this.password).toString(),
            })
            if (res && res.success) {
                this.successChagePwd = false  
            }
        },
    },
}
