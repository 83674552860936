
export default {
    data() {
        return { loading: false }
    },

    methods: {
        start() {
            console.log('Loading start')
            this.loading = true
        },

        finish() {
            console.log('Loading finish')
            this.loading = false

            /**
             * 개발 서버에서 시각적으로 보이지 않아서 timeout 줌
             * production에서는 삭제 예정
             */
            setTimeout(() => {
                this.loading = false
            }, 300 * Math.random())
        },
    },
}
