
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import api from '~/api/' // server
import storage, { keys } from '~/services/storage'

export default {
    name: 'LoginModal',
    data() {
        return {
            id: '',
            password: '',
            checkedIdSave: false,
            errorMessage: '',
            // loginInfo: null,
        }
    },

    computed: {
        ...mapState('modal', ['loginShow', 'loginPath']),
        ...mapState('member', ['memberInfo']),
    },
    watch: {
        loginShow() {
            if (this.loginShow) {
                // id 저장 여부 처리\
                const savedId = storage.get(keys.id)
                if (savedId) {
                    this.id = savedId
                    this.checkedIdSave = true
                }

                /**
                 * keys.loginInfo 정보가 저장되어 있으면 로그인 상태임
                 */
                const loginInfo = storage.get(keys.loginInfo) // 저잗된 값이 없으면 null
                console.log('beforeMount loginInfo', loginInfo)

                // 회원정보 store에 갱신
                if (loginInfo) {
                    this.getMemberInfo(loginInfo.id)
                }
            }
        },
    },

    methods: {
        ...mapActions('member', ['GET_MEMBER_INFO', 'LOGOUT']),
        ...mapActions('modal', ['SHOW_ALERT', 'SHOW_FIND_ID', 'HIDE_MODAL']),

        onClickClose() {
            this.close()

            // field 초기화
            this.clearField()
        },
        onClickConfirm() {
            this.close()
        },

        close() {
            this.loginShow.callback && this.loginShow.callback()
            this.HIDE_MODAL()
        },
        onClickLogin() {
            if (this.id === '') {
                // this.SHOW_ALERT({ content: '아이디를 입력하세요.' })
                this.errorMessage = '아이디를 입력하세요.'
                return
            } else if (this.id.length < 4) {
                // this.SHOW_ALERT({ content: '아이디를 바르게 입력하세요.' })
                this.errorMessage =
                    '가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.'
                return
            }

            if (this.password === '') {
                // this.SHOW_ALERT({ content: '비밀번호를 입력하세요.' })
                this.errorMessage = '비밀번호를 입력하세요.'
                return
            }

            this.login()
        },
        onClickFindIdPwd() {
            this.findId()
        },

        onClickSignUp() {
            this.HIDE_MODAL()
            this.$router.push('/SignUp')
        },

        onClickLogout() {
            this.logout()
        },

        async login() {
            const params = {
                id: this.id,
                password: this.$CryptoJS.SHA1(this.password).toString(),
                // password: this.password,
            }

            const res = await api.login(params)
            console.log('login res', res)

            if (res.success === false) {
                this.errorMessage =
                    '가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.'
                return
            }

            // modal 가입완료 표시
            if (res.success) {
                // this.SHOW_ALERT({ content: '로그인 성공' })

                // 로그인 정보(로그인 유지 판단용) 스토리지에 저장
                storage.set(keys.loginInfo, res.data)

                // member store 갱신
                this.getMemberInfo(this.id)

                // id 저장
                if (this.checkedIdSave) {
                    storage.set(keys.id, this.id)
                } else {
                    storage.remove(keys.id)
                }

                // 이전 진입path가 있을 때만 push
                if (this.loginPath) {
                    this.$router.push(this.loginPath)
                }

                // field 초기화
                this.clearField()

                this.HIDE_MODAL()
            }
        },

        getMemberInfo(id) {
            const params = {
                id,
                // id: 'testid4', // TEST
            }
            this.GET_MEMBER_INFO(params)
        },

        logout() {
            /**
             * plugin/method.js 에 공통 method 사용
             */
            this.$method.logout(this.logoutCallback)
        },

        findId() {
            this.HIDE_MODAL()
            this.SHOW_FIND_ID()
        },

        logoutCallback() {
            this.resetInput()
        },

        resetInput() {
            /* 20210518 shb 추가
            로그아웃 후 저장한 id가 있다면 input 창에 저장 id 넣어준다. */
            const savedId = storage.get(keys.id)
            if (savedId) {
                this.id = savedId
                this.checkedIdSave = true
            } else {
                this.id = ''
            }
            this.password = ''
        },

        clearField() {
            this.id = ''
            this.password = ''
            this.errorMessage = ''
        },
    },
}
