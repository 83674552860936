
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import FindId from '~/components/Common/Modal/FindId'
import FindPwd from '~/components/Common/Modal/FindPwd'
import ChagePwd from '~/components/Common/Modal/ChagePwd'
export default {
    name: 'Findinfo',
    components: {
        FindId,
        FindPwd,
        ChagePwd,
    },
    data() {
        return {
            // visibleChangeTab: false, 
            idTab: true,
            pwdTab: false,
            onTab: 'id',
            reset: false,
        }
    },
    computed: {
        ...mapState('modal', ['visibleChangeTab']),
    },
    methods: {
        ...mapMutations('modal', ['SET_VISIBLECHANGETAB',]),
        ...mapActions('modal', ['HIDE_MODAL']),
    
        onClickClose() {
            this.reset = true
            // this.visibleChangeTab = false 
            this.SET_VISIBLECHANGETAB(false)
            this.HIDE_MODAL()
        },
        focusTab(value) {
            
            if (this.onTab !== value) {
                this.onTab = value
                if (value === 'id') {
                    this.idTab = true
                    this.pwdTab = false
                } else {
                    this.pwdTab = true
                    this.idTab = false
                }
            }
        },
        changePwd() {
            // this.visibleChangeTab = true
            this.SET_VISIBLECHANGETAB(true)
        },
    },
}
